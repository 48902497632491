import "intersection-observer";

import { CountUp } from "countup.js";
import Shower from "./shower";
import Flickity from "flickity";
import "flickity-imagesloaded";

const $ = (s, p = document) => p.querySelector(s);
const $$ = (s, p = document) => [...p.querySelectorAll(s)];

// const setIframeHeight = () => {
//   // Do not execute for "non-chromium" Edge because it can't count.
//   if (window.navigator.userAgent.indexOf("Edge") < 0) {
//     const bodyHeight = window.document.body.scrollHeight;

//     // The container the iFrame sits in on berlin.de: https://www.berlin.de/sen/uvk/verkehr/mobilitaetswende/verkehr-888873.php
//     const iFrameContainer = window.parent.document.getElementById("iframe_1_0");

//     window.frameElement.style.height = bodyHeight + "px";
//     iFrameContainer && (iFrameContainer.style.height = bodyHeight + "px");

//     // Wait for things to settle (especially flickity) and do it again.
//     setTimeout(() => {
//       window.frameElement.style.height =
//         window.document.body.scrollHeight + "px";
//     }, 1000);
//   }
// };

const initShower = (handler, target) => {
  new Shower({
    handler: handler,
    target: target,
    duration: 200,
    easing: "ease",
    openClassName: "is-expanded",
    //cb: () => setIframeHeight(),
  }).init();
};

class BarDiagram {
  constructor(barElements) {
    this.barElements = barElements;
  }

  getItems() {
    return this.barElements.map((el) => ({
      element: el,
      value: parseInt(el.dataset.value || el.innerText) || 0,
    }));
  }

  setWidth() {
    const items = this.getItems();
    const maxValue = Math.max(...items.map(({ value }) => value));

    items.forEach((item) => {
      item.element.style.width = `${(item.value / maxValue) * 100}%`;
    });
  }
}

const initHeroHeader = () => {
  const header = $(".hero-header-slider");
  const navButtons = $$(".hero-header-nav__button");

  let activeButtons = navButtons[0];

  const heroSlider = new Flickity(header, {
    pageDots: false,
    cellSelector: ".hero-header-slider__cell",
    adaptiveHeight: true,
    dragThreshold: 10,
    on: {
      change: (i) => {
        activeButtons.classList.remove("active");
        activeButtons = navButtons[i];
        activeButtons.classList.add("active");
      },
    },
  });

  navButtons.forEach((button, i) =>
    button.addEventListener("click", () => heroSlider.select(i))
  );

  const sliderStatus = document.querySelector(".slider-status");

  function updateStatus() {
    var slideNumber = heroSlider.selectedIndex + 1;
    sliderStatus.textContent = slideNumber + "/" + heroSlider.slides.length;
  }
  updateStatus();

  heroSlider.on("select", updateStatus);
};

const initTransportStrategy = () => {
  const $slider = $(".transport-strategy__slider");
  const navigationButtons = $$(".transport-strategy__icon-button");

  let activeButton = navigationButtons[0];

  const Slider = new Flickity($slider, {
    prevNextButtons: false,
    pageDots: false,
    on: {
      change: (i) => {
        activeButton.classList.remove("active");
        activeButton = navigationButtons[i];
        activeButton.classList.add("active");
      },
    },
  });

  navigationButtons.forEach((button, i) =>
    button.addEventListener("click", () => Slider.select(i))
  );

  $$(".strategy-diagram", $slider).forEach((diagram) =>
    new BarDiagram($$(".strategy-diagram__value", diagram)).setWidth()
  );

  // Fix initial flickity calculations being off.
  setTimeout(() => Slider.reposition(), 0);
};

const init = () => {
  $$("[data-flickity]").forEach(
    (el) =>
      new Flickity(el, {
        ...JSON.parse(el.dataset.flickity),
        on: {
         // ready: () => setIframeHeight(),
        },
      })
  );

  $$("[data-toggle-box]").forEach((el) =>
    initShower($(".qna__question button", el), $(".qna__answer", el))
  );

  initHeroHeader();
  initTransportStrategy();

  const countUpDefaults = {
    startVal: 0,
    duration: 1.5,
    separator: ".",
    decimal: ",",
    prefix: "",
    suffix: "",
  };

  $$("[data-count-up]").forEach((el) => {
    const valueTo = parseInt(el.innerText);
    const options = { ...countUpDefaults, ...JSON.parse(el.dataset.countUp) };

    new IntersectionObserver(([{ intersectionRatio }], observer) => {
      if (intersectionRatio > 0) {
        // observer.disconnect();
        const counter = new CountUp(el, valueTo, options);
        counter.start();
      }
    }).observe(el);
  });

  // Makes jump marks work even inside iFrame
  $$('a[href^="#"]').forEach((el) => {
    el.addEventListener("click", (event) => {
      const iframeOffset = window.frameElement.offsetTop;
      const offset = $(el.getAttribute("href")).offsetTop;
      window.parent.scrollTo(0, offset + iframeOffset);
      event.preventDefault();
      return false;
    });
  });

  // let prevWindowWidth = window.innerWidth;
  // addEventListener("resize", (e) => {
  //   if (window.innerWidth !== prevWindowWidth) {
  //     setTimeout(setIframeHeight, 500);
  //     prevWindowWidth = window.innerWidth;
  //   }
  // });

  // open jump mark menu
  $(".jump-marks__mobile").addEventListener("click", () => {
    $(".jump-marks__list").classList.toggle("active");
    $(".jump-marks__arrow").classList.toggle("active");
  });

  //intersection observer for Jump Marks
  const intersectSections = function () {
    const sectionElements = Array.from($$(".intersection"));
    const current = $(".current-section");

    const observer = new IntersectionObserver(
      (entries) => {
        const intersectingEntry = entries.find((entry) => entry.isIntersecting);
        if (!intersectingEntry) return;
        const title = intersectingEntry.target.querySelector("h2").innerText;
        current.textContent = title;
      },
      { rootMargin: "-20px 0px -90% 0px" },
      { threshold: 0 }
    );

    sectionElements.forEach((element) => {
      observer.observe(element);
    });
  };

  intersectSections();
  //setIframeHeight();
};

init();
